import { CloseButton, TextInput as MantineTextInput, type TextInputProps as MantineTextInputProps } from '@mantine/core'
import { mergeRefs } from '@mantine/hooks'
import { IconAlphabetLatin } from '@tabler/icons-react'
import { forwardRef, useCallback, useRef } from 'react'

export interface TextInputProps extends MantineTextInputProps {
  clearable?: boolean
  isStatic?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ clearable, isStatic, size, unstyled, value, ...props }, forwardedRef) => {
    const ref = useRef<HTMLInputElement>()

    const handleClear = useCallback(() => {
      // solution for this found at https://stackoverflow.com/a/46012210
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(ref.current, '')

      ref.current.dispatchEvent(new Event('input', { bubbles: true }))
    }, [ref])

    return !isStatic ? (
      <MantineTextInput
        mb='xs'
        icon={<IconAlphabetLatin size={18} />}
        ref={mergeRefs(forwardedRef, ref)}
        rightSection={
          value &&
          clearable && (
            <CloseButton
              onClick={handleClear}
              size={size}
              unstyled={unstyled}
              variant='transparent'
            />
          )
        }
        value={value}
        {...props}
      />
    ) : (
      <div
        style={{
          fontFamily:
            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
          lineHeight: 1.55,
          marginBottom: '0.625rem'
        }}
      >
        <div
          style={{
            display: 'inline-block',
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#212529',
            wordBreak: 'break-word',
            cursor: 'default'
          }}
        >
          {props.label}
        </div>
        <div
          style={{
            position: 'relative',
            marginBottom: 'calc(0.625rem / 2)',
            boxSizing: 'border-box',
            display: 'block'
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '2.25rem',
              color: '#adb5bd'
            }}
          >
            <IconAlphabetLatin size={18} />
          </div>
          <div
            ref={ref}
            style={{
              paddingLeft: '2.25rem',
              height: '2.25rem',
              lineHeight: 'calc(2.25rem - 0.125rem)',
              appearance: 'none',
              resize: 'none',
              boxSizing: 'border-box',
              fontSize: '0.875rem',
              width: '100%',
              display: 'block',
              border: '0.0625rem solid transparent',
              cursor: 'default',
              textAlign: 'left',
              backgroundColor: '#fff',
              transition: 'border-color 0.1s ease-in-out,box-shadow 0.1s ease-in-out',
              minHeight: '2.25rem',
              paddingRight: 'calc(2.25rem / 3)',
              borderRadius: '0.25rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {value}
          </div>
        </div>
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'
